export default function Undo() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7805 1.88696V7.2217H15.4458"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.21973 16.1131V10.7783H6.55446"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.45142 6.33263C3.90236 5.05833 4.66875 3.91902 5.67909 3.02102C6.68943 2.12302 7.91079 1.4956 9.2292 1.19729C10.5476 0.898983 11.9201 0.939518 13.2186 1.31511C14.5171 1.69071 15.6993 2.38912 16.6549 3.34518L20.7804 7.22175M1.21973 10.7782L5.34526 14.6548C6.30084 15.6109 7.48304 16.3093 8.78154 16.6849C10.0801 17.0605 11.4525 17.101 12.771 16.8027C14.0894 16.5044 15.3107 15.877 16.3211 14.979C17.3314 14.081 18.0978 12.9417 18.5487 11.6674"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
