export const idAndNameToSlug = (id: any, name: any) => {
  const sanitizedName = name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/&/g, "and")
    .replace(/[^a-z0-9-]/g, "");
  const slug = `${id}-${sanitizedName}`;
  return slug;
};

export const slugToIdAndName = (slug: any) => {
  const [id, ...nameParts] = slug.split("-");
  const name = nameParts.join(" ").replace(/-and-/g, "&");
  return { id: parseInt(id, 10), name };
};
