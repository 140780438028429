export default function Skip() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clip-rule="evenodd"
        d="M1.01187 0.430511C1.16295 0.301305 1.35914 0.237365 1.55734 0.25274C1.75554 0.268116 1.93952 0.361551 2.06887 0.512511L8.06887 7.51251C8.18533 7.64843 8.24934 7.82152 8.24934 8.00051C8.24934 8.1795 8.18533 8.35259 8.06887 8.48851L2.06887 15.4885C1.93738 15.6321 1.75522 15.7189 1.56088 15.7306C1.36655 15.7424 1.17527 15.678 1.02749 15.5513C0.879706 15.4246 0.787001 15.2453 0.76897 15.0515C0.750939 14.8576 0.808996 14.6643 0.930867 14.5125L6.51187 8.00051L0.930867 1.48851C0.801423 1.33769 0.73714 1.14165 0.752141 0.943457C0.767141 0.745267 0.860197 0.561136 1.01087 0.431511L1.01187 0.430511ZM5.01187 0.430511C5.0867 0.366381 5.17344 0.317628 5.26712 0.28704C5.36081 0.256451 5.4596 0.244627 5.55786 0.252243C5.65612 0.259858 5.75191 0.286764 5.83977 0.331422C5.92762 0.376081 6.00581 0.437617 6.06987 0.512511L12.0699 7.51251C12.1863 7.64843 12.2503 7.82152 12.2503 8.00051C12.2503 8.1795 12.1863 8.35259 12.0699 8.48851L6.06987 15.4885C5.94044 15.6397 5.75626 15.7333 5.55785 15.7486C5.35944 15.764 5.16304 15.6999 5.01187 15.5705C4.86069 15.4411 4.76713 15.2569 4.75175 15.0585C4.73637 14.8601 4.80044 14.6637 4.92987 14.5125L10.5129 8.00051L4.93187 1.48851C4.80254 1.33756 4.73843 1.14144 4.75362 0.943248C4.76881 0.745054 4.86205 0.560996 5.01287 0.431511L5.01187 0.430511Z"
        fill="white"
      />
    </svg>
  );
}
