import Skip from "@/ui/Icons/Skip";
import Switch from "@/ui/Icons/Switch";
import Undo from "@/ui/Icons/Undo";
import { motion, PanInfo, useMotionValue } from "framer-motion";
import React, { useState } from "react";
import Link from "next/link";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import Image from "next/image";
import { isSameDay, set } from "date-fns";
import { idAndNameToSlug } from "@/lib/slug";

interface CalendarCardProps {
  children?: React.ReactNode;
  date?: string;
  recipe?: object;
  item: any;
}

export default function CalendarCard({ item, fetchCalendar }: any) {
  const [isSkip, setIsSkip] = useState(item?.skipped ?? false);
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const [dragOffset, setDragOffset] = useState(0);
  //   console.log("recipe", recipe);

  const motionValue = useMotionValue(0);
  const recommendationDate = new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  }).format(new Date(item?.date));

  const skipFeedback = async () => {
    const { data, error } = await supabaseClient
      .from("calendar")
      .update({ skipped: true })
      .eq("id", item?.id);
    if (error) {
      console.log(error);
    } else {
      setIsSkip((p: any) => !p);
      fetchCalendar();
    }
  };

  const isToday = isSameDay(new Date(), new Date(item?.date));

  const skipUndo = async () => {
    const { data, error } = await supabaseClient
      .from("calendar")
      .update({ skipped: false })
      .eq("id", item?.id);
    if (error) {
      console.log(error);
    } else {
      setIsSkip((p: any) => !p);
      fetchCalendar();
    }
  };

  const replaceFeedback = () => {
    router.push(`/search?replace=true&calendarId=${item?.id}`);
  };

  const cardDragEnd = (event: any, info: PanInfo) => {
    if (info.offset.x > 200) {
      console.log("skip");
      if (isSkip) {
        skipUndo();
      } else {
        skipFeedback().then((r) => console.log(r));
      }
    }
    if (info.offset.x < -200) {
      replaceFeedback();
      console.log("switch");
    }
    if (dragOffset <= -5 || dragOffset >= 5) {
      setDragOffset(0);
    }
  };

  return (
    <article>
      <span
        className={`${
          isToday ? "text-[#007D32] font-medium" : "text-[#656364] font-normal"
        } text-base mb-3`}
      >
        {isToday
          ? `TODAY, ${recommendationDate?.substring(4)}`
          : recommendationDate}
      </span>

      <div
        className={`relative w-full mt-4 overflow-hidden ${
          isToday ? "h-[200px] " : "h-[161px]"
        } rounded-[24px] flex items-center justify-between cursor-grab active:cursor-grabbing ${
          isToday ? "shadow-sm" : ""
        }`}
      >
        <div className="w-full bg-[#E74141] h-full flex items-center justify-start pl-10">
          <div className="flex flex-col items-center gap-y-2">
            {isSkip ? <Undo /> : <Skip />}
            <span className="text-sm font-medium text-white">
              {isSkip ? "Undo" : "Skip"}
            </span>
          </div>
        </div>
        <div className="w-full bg-[#008E3E] h-full flex items-center justify-end pr-10">
          <div className="flex flex-col items-center gap-y-2">
            <Switch />
            <span className="text-sm font-medium text-white">Replace</span>
          </div>
        </div>
        <motion.button
          onClick={() => {
            dragOffset >= -5 && dragOffset <= 5
              ? router.push(
                  `/${idAndNameToSlug(
                    item?.recipe_id,
                    item?.recipe?.name
                  )}?calendar=${item?.id}`
                )
              : null;
          }}
          drag={"x"}
          dragConstraints={{
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
          dragElastic={0.4}
          dragTransition={{ bounceStiffness: 500 }}
          onDragEnd={cardDragEnd}
          onDrag={(event: any, info: PanInfo) => {
            setDragOffset(info.offset.x);
          }}
          animate={{
            transition: { duration: 0.1 },
          }}
          className={`absolute inset-0 p-7 flex items-center flex-wrap text-start rounded-3xl overflow-hidden cursour-grab ${
            isToday ? "bg-[#F4E7EC]" : "bg-white"
          } ${isSkip ? "bg-white" : "bg-[#F4E7EC]"}`}
        >
          {/* Image component */}
          {isSkip ? (
            <div className="flex items-center h-full justify-between">
              <div>
                <h2 className="font-semibold text-xl text-[#434343] mb-1">
                  It's a night off!
                </h2>
                <p className="text-sm text-[#434343]">
                  Treat yourself a yummy dinner.
                </p>
              </div>
              <div>
                <Image
                  src="/dinnerDish.png"
                  className="w-16 h-16 object-cover"
                  width={64}
                  height={64}
                  alt="food"
                />
              </div>
            </div>
          ) : (
            // <Link href={`/calendar?date=${new Date(item?.date).toISOString()}`}>
            <>
              <div
                className={`absolute -right-14 flex items-center flex-wrap rounded-full ${
                  isToday ? "top-[10%]" : "top-0"
                } w-40 h-40 overflow-hidden ring-2 ring-white `}
              >
                <Image
                  className="w-full h-full object-cover"
                  fill
                  src={
                    item?.recipe?.imageURL ??
                    "https://roeruzduzbrzkwkdfkfr.supabase.co/storage/v1/object/public/recipes/default-recipe.png"
                  }
                  draggable={false}
                  alt={item?.recipe?.name}
                />
              </div>
              <h3 className="text-xl font-semibold w-4/5 pb-5">
                {item?.recipe?.name}
              </h3>
              <div className="inline-flex gap-x-2">
                <span
                  className={`inline-flex items-center gap-x-2 p-2 rounded-xl text-sm ${
                    recommendationDate === "Today" ? "bg-white" : "bg-[#F9F7F7]"
                  }`}
                >
                  <p>🔪</p>
                  {item?.recipe?.prepTime} mins
                </span>
                <span
                  className={`inline-flex items-center gap-x-2 p-2 rounded-xl text-sm ${
                    recommendationDate === "Today" ? "bg-white" : "bg-[#F9F7F7]"
                  }`}
                >
                  <p>🍳</p>
                  {item?.recipe?.cookTime} mins
                </span>
              </div>
            </>
          )}
        </motion.button>
      </div>
    </article>
  );
}
