export default function Switch({ color, ...props }: any) {
  return (
    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M11.0769 1.2312L13.5384 3.69274L11.0769 6.15428"
        stroke={color ? color : "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.46155 7.38445V6.15368C2.46155 5.50084 2.72089 4.87473 3.18252 4.41311C3.64414 3.95148 4.27025 3.69214 4.92309 3.69214H13.5385"
        stroke={color ? color : "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92309 14.77L2.46155 12.3085L4.92309 9.84692"
        stroke={color ? color : "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5385 8.61572V9.84649C13.5385 10.4993 13.2791 11.1254 12.8175 11.5871C12.3559 12.0487 11.7298 12.308 11.0769 12.308H2.46155"
        stroke={color ? color : "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
